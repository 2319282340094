<template>
  <div class="common-bg">
    <van-nav-bar
      title="常见问题"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="padded-15">
      <div>
        <p>1：提现和充值</p>
        <p> 提现时间是09：00至21：00，提现12小时内到账！充值审核时间是09：00至23：59，在审核时间内充值通常2小时内到帐，最长不会超过4小时，特殊情况除外！24小时皆可充值！</p>
        <p>2：股权，基金，货币分红情况</p>
        <p>股权一经购买可永久享受每日分红，购买起24小时候产生分红！</p>
        <p>基金持有期三年，持有期间每天可享受分红，自购买起24小时候产生分红！基金到期后丰民财富公司会返回用户购买的全部本金！</p>
        <p>货币具有极大的升值空间，每天的价格都会上涨，持有起90天后可直接通过本软件按照实时价格出售并且提现！</p>
        <p>3：资金安全</p>
        <p>委托银行第三方机构对用户账户进行资金管理，用户可以实时查询到资金账户的详情，本APP采用国际领先的系统加密及保护技术24小时监控，内部拥有严格的资金管理流程和完善安全的系统！数据安全承诺，采取各种合适的物理、电子和管理方面的措施来保护数据，以实现对数据安全的承诺。<br></p>
        <p>4：我的资产包含什么？<br>我的资产包含股权，基金，货币，原始股权的总市值！<br>账户余额包含可提现余额，充值的金额和签到的金额！<br>可提现余额包含每日分红和推荐的佣金奖励！</p>
        <p>5：原始股权有什么优势？<br>原始股权的发行价极低，等待公司上市之后具有极大的升值空间而且可以享受每年分红！</p>
        <p>6：如何查看我的充值、提现，购买的股权基金订单有没有通过？<br>点击我的，购买记录查询，提现记录查询、股权基金资产查询即可看到详细的记录。</p>
        <p>7：如何查看我的推奖奖励？<br>点击我的团队，可以看到所有下级会员和对应的推荐奖励</p>
        <p>8：如何查看基金和股权分红？<br>点击股权查询或者基金查询即可看到今日收益。或者点击收支明细，就可以看到每一个订单的分红！</p>
        <p>如有不明白的问题请及时联系客服！</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'problem',
  data () {
    return {}
  },
  created () {},
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
